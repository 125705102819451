import React from 'react';
import './Num.css';

const NumBoard = () => {
    return (
        <>
        <div className="number--group">
            <div className="number--element">
                <p className='num'>10</p>
                <div className="line--below"></div>
                <p className='text--below'>Years In Robocon</p>
            </div>
            <div className="number--element">
                <p className='num'>350+</p>
                <div className="line--below"></div>
                <p className='text--below'>Student Members</p>
            </div>
            <div className="number--element">
                <p className='num'>22+</p>
                <div className="line--below"></div>
                <p className='text--below'>Robots Built</p>
            </div>
            <div className="number--element">
                <p className='num'>84+</p>
                <div className="line--below"></div>
                <p className='text--below'>Projects Completed</p>
            </div>
        </div>            
        </>
    )
}

export default NumBoard;
