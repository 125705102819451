import styled from 'styled-components';
import { Link as LinkRoute } from 'react-router-dom';


export const Nav = styled.nav`
  height: 15vh;
  display: flex-start;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  position: relative;/*sticky */
  top: 0;
  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`
export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  z-index: 1;
  width: 100%;
  padding: 0 80px;
  background: transparent;
  @media screen and (max-width: 960px) {
    padding: 0 24px;
  }
`
export const NavLogo = styled(LinkRoute)`
  /* color: #000000; */
  background: transparent;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  z-index: 4;
`
export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 1100px){
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000000;
  }
`
export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  @media screen and (max-width: 1100px){
    display: none;
  }
`
export const NavItem = styled.li`
  height: 80px;
`
export const NavLinks = styled(LinkRoute)`
  color: #000000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 3rem;
  height: 100%;
  cursor: pointer;

  &.active{
    border-bottom: 3px solid #01bf71;
  }
  &:hover{
    color: #DC1D28;
    transition: 0.2 ease-in-out;
  }
`
export const ButtonLinks = styled(LinkRoute)`
  color:rgb(255, 255, 255);
  background: #DC1D28;;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 3rem;
  height: 100%;
  cursor: pointer;

  &.active{
    border-bottom: 3px solid #01bf71;
  }
  &:hover{
    transition: 0.2 ease-in-out;
  }
`
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  justify-self: flex-end;
  @media screen and (max-width: 1100px){
    display: none;
  }
`
export const NavBtnLink = styled(LinkRoute)`
  border-radius: 50px;
  background: #000000;
  white-space: nowrap;
  padding: 15px 22px;
  color: #ffffff;
  font-size: 1.3rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover{
    transition: all 0.2s ease-in-out;
    background: #212020;
    color: #ffffff;
  }
`
