import React from 'react';
import Button from '../../Button/Button';
import './Banner.css';
import { ButtonLinks} from '../../Navbar/NavElements';

const Banner = () => {
    return (
        <>
        <div className="banner--container">
            <div className="banner--data">
                <p className='topline' >We're Building Better Robots Every Year!</p>
                <p className='subtitle'>An Undergrads Team Representing Marathwada Mitra Mandal's College of Engineering at National Level ABU-ROBOCON</p>
                <div className="trans--button">
                    <Button NavLinks to="history" buttonSize='btn--wide' buttonColor='red'><ButtonLinks to="history">Check out our Robots</ButtonLinks></Button>
                    <Button buttonSize='btn--wide' buttonColor='see-through'>
                        <a href='https://youtu.be/M4HJpKvIwUA?si=0DTvrKJgr_3koYqN' target='_blank' rel="noreferrer" className='isWhite'>Watch Video</a>
                    </Button>
                </div>
            </div>
            <div className="banner--graphics">
                <div className="img-a">
                        <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/2023%2Fer%20nice%20pic.jpg?alt=media&token=632a10e8-e488-42d9-84de-301226046226' alt='A'></img>
                    {/* <img src='images/dph1.jpg' alt='A'></img> */}
                </div>
                <div className="img-b">
                        <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/natu_web.jpg?alt=media&token=62048c13-2226-493f-8e49-b27db3d4992f' alt='B'></img>
                    {/* <img src='images/dph2.jpg' alt='B'></img> */}
                </div>
                <div className="img-c">
                        <img src='images/R1_PCB.JPG' alt='C'></img>
                    {/* <img src='images/dph3.jpg' alt='C'></img> */}
                </div>
            </div>
        </div>
        </>
    )
}

export default Banner;
