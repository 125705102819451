import React from 'react';
import CardItem from './CardItem';
import './Sponsors.css';

const Sponsors = () => {
  return (
    <>
        <div className="empty">
            <p className='sponsor--header'>Our Proud Sponsors</p>
        </div>

		<div class="client">
            <ul>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Fsolidworks.jpg?alt=media&token=aa5c7d78-0d6e-428d-99a6-94e9fc64b429' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FAlligator%20logo_PNG%20(1).png?alt=media&token=e14b7379-4fe1-4497-be4d-d54eded9d398' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FNextwave%20Logo.jpg?alt=media&token=2e8dfa32-e96e-4429-9c10-ff7f9fecf7b0' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Fdream%20polymer.png?alt=media&token=b056f2e5-53a0-43f1-b702-e9e05422a666' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Fbrose.jpg?alt=media&token=b3d0c322-adcb-43f4-8544-abf8c52650ec' alt="client2"/>
                </li>
                
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FIngenium_Logo-removebg-preview.png?alt=media&token=097cc204-0bcb-44b2-bde9-4b5be42cbc0a' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FAltium_Black_Logo.png?alt=media&token=9ffa1084-9198-4c0b-9d2f-e059edd1e808' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2F3dx_companylogo_black-242x41-f21b3443-b412-4dfd-99d4-e6e092189307.png?alt=media&token=02278d45-6adf-47e0-bb5d-ec16f10e0883' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FRBS_Logo-removebg-preview.png?alt=media&token=99fbae0c-913c-43b9-8d86-4d1152d7d016' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Feps_pcb-removebg-preview.png?alt=media&token=71b984ec-2625-4d82-85ff-51ea84e403e0' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FMicroLaser-removebg-preview.png?alt=media&token=8e46b6c9-4f64-4bf7-9165-a2125190ddfe' alt="client2"/>
                </li>
                
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FSculp3D.jpg?alt=media&token=6d88ed41-c961-4174-936b-bf4543640493' alt="client2"/>
                </li>
                
            </ul>
	</div>

    <ul className='mlist'>
    <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Fsolidworks.jpg?alt=media&token=aa5c7d78-0d6e-428d-99a6-94e9fc64b429' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FAlligator%20logo_PNG%20(1).png?alt=media&token=e14b7379-4fe1-4497-be4d-d54eded9d398' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FNextwave%20Logo.jpg?alt=media&token=2e8dfa32-e96e-4429-9c10-ff7f9fecf7b0' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Fdream%20polymer.png?alt=media&token=b056f2e5-53a0-43f1-b702-e9e05422a666' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Fbrose.jpg?alt=media&token=b3d0c322-adcb-43f4-8544-abf8c52650ec' alt="client2"/>
                </li>
                
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FIngenium_Logo-removebg-preview.png?alt=media&token=097cc204-0bcb-44b2-bde9-4b5be42cbc0a' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FAltium_Black_Logo.png?alt=media&token=9ffa1084-9198-4c0b-9d2f-e059edd1e808' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2F3dx_companylogo_black-242x41-f21b3443-b412-4dfd-99d4-e6e092189307.png?alt=media&token=02278d45-6adf-47e0-bb5d-ec16f10e0883' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FRBS_Logo-removebg-preview.png?alt=media&token=99fbae0c-913c-43b9-8d86-4d1152d7d016' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2Feps_pcb-removebg-preview.png?alt=media&token=71b984ec-2625-4d82-85ff-51ea84e403e0' alt="client2"/>
                </li>
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FMicroLaser-removebg-preview.png?alt=media&token=8e46b6c9-4f64-4bf7-9165-a2125190ddfe' alt="client2"/>
                </li>
                
                <li>
                    <img src='https://firebasestorage.googleapis.com/v0/b/rtr-website-23f62.appspot.com/o/sponsors%2FSculp3D.jpg?alt=media&token=6d88ed41-c961-4174-936b-bf4543640493' alt="client2"/>
                </li>
    </ul>

    </>
  )
}

export default Sponsors;